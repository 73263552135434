//  § Funkcija za carousel-thumbs (baneri na home page)
// --------------------------------------------------------------------------

// source: ttp://stackoverflow.com/questions/20007610/bootstrap-3-carousel-multiple-frames-at-once 


$('.carousel-thumbs-control-right').click(function () {
    //$(this).blur();
    $(this).parent().find('.carousel-thumbs-item').first().insertAfter($(this).parent().find('.carousel-thumbs-item').last());
});
$('.carousel-thumbs-control-left').click(function () {
    //$(this).blur();
    $(this).parent().find('.carousel-thumbs-item').last().insertBefore($(this).parent().find('.carousel-thumbs-item').first());
});



