$(function () {
    var fs;
    // ==========================================================================
    //  § Header navigacija
    // ========================================================================== 

    //  § Funkcija za toggle hamburgera
    // -------------------------------------------------------------------------- 
    function zatvoriOtvoriHamburger() {
        // definisanje varijabli i dodavanje vrednosti
        var el_site_header = $('.site-header'),                     // promenljivoj el_site_header dodaje se klasa .site-header
            el_body = $('body');

        $('#btnHamburger').on('click', function () {                // funkcija se aktivira na touch
            el_site_header.toggleClass('hamburger-open');           // pozivamo klasu .hamburger-open (nav-header.scss)

            if (el_site_header.hasClass('hamburger-open')) {        // ako je hamburger otvoren onda
                el_body.css('overflow', 'hidden');                  // body {overflow: hidden}
            } else {                                                // u suprotnom
                el_body.css('overflow', 'auto');                    // body {overflow: auto}
            }
        });
    }


    //  § Funkcija za promenu strelice kod toggle collapse podsajtova 
    // -------------------------------------------------------------------------- 

    // naša nadogradnja na boostrap collapse za toggle
    // izvor: stackoverflow.com/questions/18147338/twitter-bootstrap-3-0-icon-change-on-collapse 

    function okreniStrelicuPodsajtovi() {
        if (window.matchMedia('(max-width: 960px)').matches) {
            $('.collapse')
            .on('shown.bs.collapse', function () {
                $(this)
                    .parent()
                    .find('.glyphicon-menu-down')               // nađi klasu .glyphicon-menu-down
                    .removeClass('glyphicon-menu-down')         // ukloni klasu .glyphicon-menu-down
                    .addClass('glyphicon-menu-up');             // primeni klasu .glyphicon-menu-up
            })

            .on('hidden.bs.collapse', function () {
                $(this)
                    .parent()
                    .find('.glyphicon-menu-up')
                    .removeClass('glyphicon-menu-up')
                    .addClass('glyphicon-menu-down');
            })
        }

        else {
            $('.collapse')
            .on('shown.bs.collapse', function () {
                $(this)
                    .parent()
                    .find('.glyphicon-menu-up')
                    .removeClass('glyphicon-menu-up')
                    .addClass('glyphicon-menu-down');
            })
        };
    }
    
  
    //  § Funkcija za zatvaranje podsajtova kada se klikne bilo gde izvan podsajtova (za mobilni)
    // -------------------------------------------------------------------------- 

    // modifikovano poslednje resenje na strani: 
    // stackoverflow.com/questions/21240594/how-to-hide-my-collapse-bootstrap-3-navbar-with-click-on-body-when-collapse-is 

    function zatvoriOtvoriPodsajtovi() {
        $(document).on('click', function (event) {
            var $navpodsajtovi = $('.collapse.nav-podsajtovi');
            var _opened = $navpodsajtovi.hasClass('in');
            if (_opened === true) {
                $navpodsajtovi.collapse('hide');
            }
        });
    }



    // ==========================================================================
    //  § Navigacija usluge (Mega menu)
    // ========================================================================== 

    //  § Funkcija za otvaranje mega menu-ja (za mobilni)
    // --------------------------------------------------------------------------
    function zatvoriOtvoriMegaMenu() {
        $('#navUslugeBtn').on('click', function () {
            $('.mega-menu').toggleClass('menu-open');
        });
    }


    //  § Funkcija za otvaranje/zatvaranje nivoa 1 i prikaz aktivnog taba
    // --------------------------------------------------------------------------
    function zatvoriOtvoriNivo1() {
        $('.nivo1-naslov').on('mouseover', function () {
            var el = $(this);
            var target = el.siblings('.nivo1');

            $('.nivo1.open').not(target).removeClass('open');

            target.toggleClass('open');

            if (target.hasClass('open')) {
                el.addClass('active');
                $('.nivo1-naslov.active').not(el).removeClass('active');
            } else {
                el.removeClass('active');
            }
        });
    }
        

    //  § Funkcija za sakrivanje mega-menu kad se miš skloni sa njega (za desktop)
    // --------------------------------------------------------------------------    
    function megaMeniUkloni() {
        var desktopView = $(document).width();
        var trenutniMenuItem;

        if (desktopView >= '992') {
            $('.nivo1').on('mouseleave', function () {
                $(this).removeClass('open').siblings(this);
                $('.nivo1-naslov.active').removeClass('active');
            });

            $('.nivo1-naslov').on('mouseleave', function () {
                trenutniMenuItem = $(this);
                $(this).removeClass('active').siblings(this);
                $('.nivo1.open').removeClass('open').siblings(this);

            });
            $('.nivo1').on('mouseover', function () {
                $(this).addClass('open').siblings(this);
                trenutniMenuItem.addClass('active');
            });
        }
    }


    //  § Funkcija za otvaranje izabranog accordiona u nivou 2 i zatvaranje otvorenog (za mobilni)
    // --------------------------------------------------------------------------
    function zatvoriOtvoriNivo2() {
        $(document).on('click', function (event) {
            var $megaMeniNivo2 = $(".nivo2-sadrzaj.collapse");
            var _opened = $megaMeniNivo2.hasClass("in");

            if (_opened === true) {
                $megaMeniNivo2.collapse('hide');
            }
        });
    }


    //  § Funkcija koja nivo 2 prikazuje otvoren i bez strelice (za desktop)
    // --------------------------------------------------------------------------
    function megaMeniPrikaz() {
        var desktopView = $(document).width();

        if (desktopView >= '992') {
            $('.nivo2-naslov a').attr('data-toggle', '');               // does not collapse 
            $('.nivo2-naslov a').removeClass('collapse-symbol-right');
            $('.nivo2-naslov a').removeClass('collapsed');
            $('.nivo2-naslov a').click(function () { return false; });  // ukloni link na desktopu
        } else {
            $('.nivo2-naslov a').attr('data-toggle', 'collapse');
            $('.nivo2-naslov a').addClass('collapse-symbol-right');
            $('.nivo2-naslov a').addClass('collapsed');
        }
    }




    //
    //  § Funkcija za osvežavanje (reload) strane ako se radi resize
    // --------------------------------------------------------------------------

    //function ponovoUcitaj() {
    //    $(window).bind('resize', function (e) {
    //        if (window.RT) clearTimeout(window.RT);
    //        window.RT = setTimeout(function () {
    //            this.location.reload(false); / false to get page from cache /
    //        }, 500);
    //    });
    //}


    // ==========================================================================
    //  § Funkcija koja u slucaju izbora full screen opcije za video materijale i mape 
    //  sprecava refresh strane, tj. automatsko zatvaranje full screena
    // ========================================================================== 
    function dodajListenereZaFullScreen() {

        $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', function (e) {
            clearTimeout(fs);
            fs = setTimeout(function () {
                if (window.RT) clearTimeout(window.RT);
            }, 200);
        });
    }

    



   


    // ==========================================================================
    //  § Pozivanje funkcija
    // ========================================================================== 

    $(function () {
        // pokretanje funkcija: onload
        zatvoriOtvoriHamburger();
        zatvoriOtvoriPodsajtovi();
        okreniStrelicuPodsajtovi();
        megaMeniPrikaz();
        megaMeniUkloni();
        zatvoriOtvoriMegaMenu();
        zatvoriOtvoriNivo1();
        zatvoriOtvoriNivo2();
     //   ponovoUcitaj();
        dodajListenereZaFullScreen()
        
        // pokretanje funkcija: resize
      /*  $(window).on('resize', function () {
            zatvoriOtvoriHamburger();
            zatvoriOtvoriPodsajtovi();
            okreniStrelicuPodsajtovi();
            megaMeniPrikaz();
            megaMeniUkloni();
            zatvoriOtvoriMegaMenu();
            zatvoriOtvoriNivo1();
            zatvoriOtvoriNivo2();
           // ponovoUcitaj();  
        }); */

    });


}); // Ne pisati ispod