// ==========================================================================
//  § Navigacija: Alati
// ========================================================================== 
$(function () {


    // ==========================================================================
    //  § Navigacija usluge (Mega menu)
    // ========================================================================== 

    //  § Funkcija za otvaranje mega menu-ja (za mobilni)
    // --------------------------------------------------------------------------
    function zatvoriOtvoriNavAlati() {
        $('#navAlatiBtn').on('click', function () {
            $('.nav-alati').toggleClass('nav-alati-open');
        });
    }


    //  § Funkcija za otvaranje/zatvaranje nivoa 1 i prikaz aktivnog taba
    // --------------------------------------------------------------------------
    function zatvoriOtvoriAlatiNivo1() {
        $('.nivo1-alati-naslov').on('click', function () {
            var el = $(this);
            var target = el.siblings('.nivo1-alati');

            $('.nivo1-alati.open').not(target).removeClass('open');

            target.toggleClass('open');

            if (target.hasClass('open')) {
                el.addClass('active');
                $('.nivo1-alati-naslov.active').not(el).removeClass('active');
            } else {
                el.removeClass('active');
            }
        });
    }


    //  § Funkcija za otvaranje izabranog accordiona u nivou 2 i zatvaranje otvorenog (za mobilni)
    // --------------------------------------------------------------------------
    function zatvoriOtvoriAlatiNivo2() {
        $(document).on('click', function (event) {
            var $navAlatiNivo2 = $(".nivo2-alati-sadrzaj.collapse");
            var _opened = $navAlatiNivo2.hasClass("in");

            if (_opened === true) {
                $navAlatiNivo2.collapse('hide');
            }
        });
    }



    //  § Funkcija koja nivo 2 prikazuje otvoren i bez strelice (za desktop)
    // --------------------------------------------------------------------------
    function navAlatiPrikaz() {
        var desktopView = $(document).width();

        if (desktopView >= '992') {
            $('.nivo2-alati-naslov a').attr('data-toggle', '');               // does not collapse 
            $('.nivo2-alati-naslov a').removeClass('collapse-symbol-right');
            $('.nivo2-alati-naslov a').removeClass('collapsed');
            $('.nivo2-alati-naslov a').click(function () { return false; });  // ukloni link na desktopu
        } else {
            $('.nivo2-alati-naslov a').attr('data-toggle', 'collapse');
            $('.nivo2-alati-naslov a').addClass('collapse-symbol-right');
            $('.nivo2-alati-naslov a').addClass('collapsed');
        }
    }


    //  § Funkcije za otvaranje i zatvaranje popovera na click
    // -------------------------------------------------------------------------- 


    // ==========================================================================
    //  § Funkcija za osvežavanje (reload) strane ako se radi resize
    // ========================================================================== 

    //function ponovoUcitaj() {
    //    $(window).bind('resize', function (e) {
    //        if (window.RT) clearTimeout(window.RT);
    //        window.RT = setTimeout(function () {
    //            this.location.reload(false); / false to get page from cache /
    //        }, 500);
    //    });
    //}
    // ==========================================================================
    //  § Pozivanje funkcija
    // ========================================================================== 

    $(function () {
        // pokretanje funkcija: onload
        //zatvoriOtvoriHamburger();
        //zatvoriOtvoriPodsajtovi();
        //okreniStrelicuPodsajtovi();
        navAlatiPrikaz();
        //megaMeniUkloni();
        zatvoriOtvoriNavAlati();
        zatvoriOtvoriAlatiNivo1();
        zatvoriOtvoriAlatiNivo2();
        //ponovoUcitaj();

        // pokretanje funkcija: resize
        //$(window).on('resize', function () {
            //zatvoriOtvoriHamburger();
            //zatvoriOtvoriPodsajtovi();
            //okreniStrelicuPodsajtovi();
            //navAlatiPrikaz();
            //megaMeniUkloni();
            //zatvoriOtvoriNavAlati();
            //zatvoriOtvoriAlatiNivo1();
            //zatvoriOtvoriAlatiNivo2();
            //ponovoUcitaj();
        //});

    });


}); // Ne pisati ispod